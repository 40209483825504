@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  width: 100%;
  height: 100%;
}

.is-in-proximity {
  @apply bg-white bg-opacity-10 border border-neutral-100 border-solid;
}

.scramble a {
  text-decoration: none;
  position: relative;
}

.scramble a:after {
  content: "";
  position: absolute;
  bottom: -2px; /* Adjust the value to control the distance of the underline from the text */
  left: 0;
  width: 100%;
  height: 2px; /* Adjust the value to control the thickness of the underline */
  background-color: white; /* Adjust the color of the underline */
  transform: scaleX(0); /* Initially hide the underline */
  transform-origin: left;
  transition: transform 0.2s ease; /* Add a transition effect to the underline */
}

.scramble a:hover:after {
  transform: scaleX(1); /* Show the underline on hover */
}

#dots {
  z-index: -1;
  position: fixed;
  overflow: hidden;
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  /* darken it up a bit */
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../static/images/dots.png");

  /* pulse opacity */
  animation-name: pulse;
  animation-duration: 4s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
}

@keyframes pulse {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

#logo-svg {
  filter: url("#glitch");
}

#logo,
#links li,
#socials li,
#circle {
  visibility: hidden;
  transform: translateY(-48px);
}

#clouds {
  opacity: 0;
  visibility: hidden;
}

/* Site should still work without JavaScript enabled */
.no-script #logo,
.no-script #links li,
.no-script #socials li,
.no-script #circle {
  visibility: visible;
  transform: translateY(0px);
}
